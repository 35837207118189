import styled from 'styled-components';
import { color } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Main = styled.div`
  margin-left: 70px;
  height: 100vh;
  width: calc(100vw - 70px);
  overflow-y: hidden;
  overflow-x: auto;
  position: fixed;

  &::-webkit-scrollbar {
    display: none;
  }

  h1 {
    font-size: 120px;
    line-height: 120px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${color.black};
  }

  ${MEDIA.PHONE`
    margin-left: 0px;
    width: calc(100% - 40px);
    overflow-y: auto;
    position: relative;
    height: auto;
    margin: 20px;

    h1 {
      font-size: 30px;
      line-height: 40px;
    }
  `};
`;

export const Container = styled.div`
  padding: 30px 70px;

  ${MEDIA.PHONE`
    padding: 0;
  `};
`;
