import React from 'react';
import PropTypes from 'prop-types';
import { Main, Container } from './page.css';

const Page = ({ children, contained }) => {
  if (contained) {
    return (
      <Main>
        <Container>{children}</Container>
      </Main>
    );
  }
  return <Main>{children}</Main>;
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  contained: PropTypes.string,
};

export default Page;
